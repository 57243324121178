import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';
import {MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {Observable, of, BehaviorSubject, tap, timer, from} from 'rxjs';
import {map, catchError, first, switchMap, distinctUntilChanged} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../../environments/environment';
import {RouteEnum} from '../enums/route.enum';
import {NotificationService} from './notification.service';
import {StorageService} from './storage.service';
import {deepCopy, isUiLessTemplate, removeCookies} from '../utilities';
import {REG_EXP} from '../constants/reg-exp';
import {FieldEnum} from '../enums/field.enum';
import {ParamEnum} from '../enums/param.enum';
import {OrganizationEnum} from '../enums/organization.enum';
import {IProfile, ICurrentUser, IMetaData, IPasswordPostData, ITwoFactorAuthPostData, IResponse} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly profileSubject = new BehaviorSubject<IProfile | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private keycloakService: KeycloakService
  ) {}

  /**
   * The redirect to http on server have been there all the time.
   * The login is ok, but because server itself is not on https it suggests http for redirect.
   * It is behind load-balancer which also handles ssl.
   * This should just be ignored by client as long as LtpaToken is in place.
   * @param currentUser
   */
  private authorize(currentUser: ICurrentUser): Observable<boolean> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this.http
      .post<boolean>(environment.login, this.getAuthPostData(currentUser), {headers, withCredentials: true})
      .pipe(catchError(() => of(this.isAuthenticated)));
  }

  // Keep until keycloak PROD integration
  // public checkAuthorization(url?: string): Observable<boolean> {
  //   console.log(url);
  //   console.log(this.router.url);
  //   if (this.isUiLessTemplate) {
  //     return of(true);
  //   }
  //   if (!this.isAuthenticated) {
  //     if (url === RouteEnum.Home || url === `/${RouteEnum.Dashboard}`) {
  //       this.router.navigate([RouteEnum.Auth]);
  //     } else if (url === RouteEnum.Auth) {
  //       return of(false);
  //     }
  //     return inject(DialogInjectorService)
  //       .openAuthDialog()
  //       .pipe(map(Boolean));
  //   }
  //   return this.isProfileAvailable;
  // }

  public checkAuthorization(url?: string): Observable<boolean> {
    // Skip authorization check on UI less pages.
    // Keycloak login screen skipped on UI less pages (unauthorized/anonymous access).
    if (this.isUiLessTemplate) {
      return of(true);
    }

    // Refresh token when expired
    if (this.keycloakService.isTokenExpired()) {
      return from(this.keycloakService.updateToken(-1)).pipe(
        switchMap(updated => (updated && this.keycloakService.isLoggedIn() ? this.isProfileAvailable : of(false)))
      );
    }

    // Pages could contain several api calls (like dashboard or incident details) and some of them could be in queues.
    // Token validity should be minimum 5 minutes (300 seconds) before guard will provide access to the page.
    // We need to be sure that all api calls will return the needed data during this period.
    // User also should be able to save data on details page.
    if (this.keycloakService.isLoggedIn()) {
      return from(this.keycloakService.updateToken(300)).pipe(switchMap(() => this.isProfileAvailable));
    }
    return of(false);
  }

  public login(currentUser: ICurrentUser): Observable<IProfile | boolean> {
    return this.authorize(currentUser).pipe(
      switchMap(authenticated => {
        if (authenticated) {
          return this.fetchProfile().pipe(
            switchMap(profile => {
              if (profile) {
                if (currentUser.rememberMe) {
                  this.storageService.setCurrentUser(currentUser.username);
                } else {
                  this.storageService.removeCurrentUser();
                }
                this.openSnackbar('NOTIFICATIONS.LOGGED-IN');
              }
              return of(this.isUiLessTemplate || profile);
            })
          );
        }
        this.openSnackbar('NOTIFICATIONS.WRONG-USERNAME-OR-PASSWORD', true);
        return of(false);
      })
    );
  }

  private getAuthPostData(currentUser: ICurrentUser): string {
    const username = encodeURIComponent(currentUser.username);
    const password = encodeURIComponent(currentUser.password);
    return `username=${username}&password=${password}`;
  }

  /**
   * Returns locally stored profile if it's relevant. Gets profile from api if local profile is not relevant.
   * Subscribe to {@link getProfile} for the latest profile.
   */
  public fetchProfile(): Observable<IProfile | boolean | null> {
    if (this.isProfileRelevant || !navigator?.onLine) {
      return this.getLocalProfileSettings();
    } else if (!this.isUiLessTemplate && navigator?.onLine) {
      return this.getApiProfileSettings();
    }
    return of(null);
  }

  private getLocalProfileSettings(): Observable<IProfile> {
    const currentProfile = this.currentProfile;
    if (currentProfile) {
      if (currentProfile.language) {
        const language = this.storageService.getLanguage(currentProfile.language);
        this.translateService.use(language);
      }
      if (!this.profileSubject.value) {
        this.profileSubject.next(currentProfile);
      }
    }
    return of(currentProfile);
  }

  /**
   * TODO check usage and remove if roles isn't in use to exclude extra api call
   * @private
   */
  private getAakSafetyRoles(): Observable<string[]> {
    return this.http.get<IResponse<{roles: string[]}>>(`${environment.iStudyApi}/scope/roles/events`).pipe(
      map(res => res?.data?.roles || []),
      catchError(() => of([]))
    );
  }

  private getProfileSettings(org?: string): Observable<IProfile> {
    let params = new HttpParams();
    if (org) {
      params = params.set(ParamEnum.Org, org);
    }
    const url = `${environment.ibricksApiV3}/settings/profile`;
    return this.http
      .get<{data: IProfile; meta: IMetaData}>(url, {params})
      .pipe(switchMap(res => this.handleProfile(res?.data)));
  }

  private handleProfile(profile: IProfile): Observable<IProfile> {
    if (profile && [OrganizationEnum.AAKSafety, OrganizationEnum.Outreach].includes(profile.companySettings?.compDir)) {
      return this.getAakSafetyRoles().pipe(
        map(roles => {
          if (roles.length) {
            profile.roles = roles;
          }
          return profile;
        })
      );
    }
    return of(profile);
  }

  private getApiProfileSettings(): Observable<IProfile | boolean> {
    return this.getProfileSettings().pipe(
      map(data => this.setProfileData(data)),
      catchError(() => this.handleProfileError())
    );
  }

  private handleProfileError(): Observable<boolean> {
    // removeCookies();
    // // Opens snackbar with timeout to display it on top of the dialog backdrop
    // setTimeout(() => this.openSnackbar('NOTIFICATIONS.FAILED-TO-GET-PROFILE', true), 100);
    // if (!this.router.url.includes(RouteEnum.Auth) && environment.production) {
    //   return this.dialogInjectorService.openAuthDialog();
    // }
    // return of(false);

    removeCookies();
    this.profileSubject.next(null);
    this.openSnackbar('NOTIFICATIONS.FAILED-TO-GET-PROFILE', true);
    return of(false);
  }

  /**
   * Sets UI translation according to profile.
   * Triggers {@link updateProfile} function.
   * Sets null into profileSubject when profile is missing to trigger profile subscription in {@link AppComponent.subscribeProfile}
   * @param profile
   */
  public setProfileData(profile?: IProfile): IProfile | boolean {
    if (profile?.userSettings) {
      const language = this.storageService.getLanguage(profile?.language);
      this.translateService.use(language);
      this.updateProfile(profile);
      this.storageService.setTimestamp();
      // Renew password redirection should be removed after keycloak integration with own renewPassword functionality
      if (profile.renewPassword) {
        const commands = [
          RouteEnum.Home,
          RouteEnum.Profiles,
          RouteEnum.Password,
          RouteEnum.Change,
          profile?.userSettings?.userUnid
        ];
        this.router.navigate(commands, {queryParams: {renewPassword: '1'}});
      }
      return profile;
    }
    this.profileSubject.next(null);
    return false;
  }

  /**
   * Get the latest profile, and listen for changes
   */
  public getProfile(): Observable<IProfile> {
    return this.profileSubject.asObservable();
  }

  public changeOrganization(org: string): void {
    this.getProfileSettings(org)
      .pipe(
        catchError(err => {
          this.openSnackbar('NOTIFICATIONS.ORGANIZATION-NOT-CHANGED', true);
          return of(err?.error);
        })
      )
      .subscribe((profile: IProfile) => {
        if (profile?.unid) {
          profile.isChangedOrg = true;
          this.updateProfile(profile);
          setTimeout(() => location.replace('')); // wait a tick for correct profile storing
        }
      });
  }

  changePassword(data: IPasswordPostData): Observable<boolean> {
    return this.http
      .post<{data: boolean; meta: IMetaData}>(`${environment.ibricksApiV3}/settings/profile/password`, data)
      .pipe(
        first(),
        map(res => res?.data)
      );
  }

  forgotPassword(email: string, org: string): Observable<{data: boolean; meta: IMetaData}> {
    return this.http
      .post<{
        data: boolean;
        meta: IMetaData;
      }>(`${environment.ibricksApiV3}/settings/profile/forgotpassword`, {email, org})
      .pipe(
        first(),
        tap(res => (res?.data ? this.openSnackbar('NOTIFICATIONS.PLEASE-CHECK-YOUR-EMAIL') : null)),
        catchError(err => {
          this.openSnackbar(err?.error?.meta?.error_message, true);
          return of(err?.error);
        })
      );
  }

  public updateProfile(profile: IProfile): void {
    if (profile.userSettings) {
      this.profileSubject.next(profile);
      this.storageService.setProfile(profile);
    }
  }

  getBarcodeImageUrl(email: string): Observable<string> {
    return this.http
      .get<{data: {url: string}; meta: IMetaData}>(`${environment.ibricksApiV3}/settings/auth/barcode/${email}`)
      .pipe(
        first(),
        map(res => res?.data?.url)
      );
  }

  twoFactorAuthentication(postData: ITwoFactorAuthPostData): Observable<boolean> {
    return this.http
      .post<{data: {authenticated: boolean}; meta: IMetaData}>(`${environment.ibricksApiV3}/settings/auth`, postData)
      .pipe(
        first(),
        map(res => res?.data?.authenticated)
      );
  }

  private openSnackbar(message: string, isError?: boolean): MatSnackBarRef<TextOnlySnackBar> | null {
    return this.notificationService.openSnackbar({message, isError});
  }

  setDefaultLang(): void {
    const profileLanguage = this.currentProfile?.language;
    const language = this.storageService.getLanguage(profileLanguage);
    this.translateService.setDefaultLang(language);
  }

  public passwordFormControl(isPassword: boolean): UntypedFormControl {
    return new UntypedFormControl('', {
      validators: [Validators.required, Validators.pattern(REG_EXP.password)],
      asyncValidators: this.isMatchPasswordValidator.bind(this, isPassword)
    });
  }

  public isMatchPasswordValidator(
    isPassword: boolean,
    control: AbstractControl
  ): Observable<boolean | {match: boolean}> {
    const compareCtrl = control?.parent?.get(isPassword ? FieldEnum.Confirm : FieldEnum.Password);
    if (compareCtrl?.invalid) {
      compareCtrl?.updateValueAndValidity();
    }
    return timer(1).pipe(
      distinctUntilChanged(),
      switchMap(() => of(control.value === compareCtrl?.value ? false : {match: true}))
    );
  }

  public logOut(): void {
    removeCookies();
    sessionStorage.clear();
    // openInSameTab(`/${RouteEnum.Auth}`);
    this.keycloakService.logout();
  }

  /**
   * Returns a deep copy of stored profile.
   */
  get storedProfile(): IProfile | null {
    const profile = this.storageService.profile?.value || null;
    return profile ? deepCopy(profile) : null;
  }

  get currentProfile(): IProfile | null {
    return this.profileSubject?.value ? deepCopy(this.profileSubject.value) : this.storedProfile;
  }

  get isProfileRelevant(): boolean {
    const profile = this.storageService.profile;
    if (environment.production) {
      return !!profile && this.timestamp - profile.timestamp < 3600000; // 1h
    }
    return !!profile;
  }

  get isAuthenticated(): boolean {
    // return isAuthenticated();
    return this.keycloakService.isLoggedIn();
  }

  get timestamp(): number {
    return new Date().getTime();
  }

  get subdomain(): string {
    const hostname = location.hostname;
    const match = hostname?.match(/(.+)\.ibricks/) || null;
    let subdomain: string;

    if (match?.length >= 2 && match?.[1]) {
      subdomain = match[1] || '';
    } else {
      const except = ['www', 'localhost', 'isend', 'ipal', 'no'];
      const splitted = hostname.split('.').filter(i => !except.includes(i));
      subdomain = splitted[0] || '';
    }

    const testSites = ['test', 'develop', 'staging.develop'];
    return subdomain && testSites.includes(subdomain) ? OrganizationEnum.IBricks : subdomain || '';
  }

  get org(): string {
    const org =
      this.subdomain || this.currentProfile?.companySettings?.compDir || this.currentProfile?.defaultOrganization || '';
    return org || (environment.production ? '' : OrganizationEnum.Test); // exception for 'registrations/fill/:id' (anonymous) route in DEV
  }

  get isUiLessTemplate(): boolean {
    return isUiLessTemplate(location.pathname);
  }

  get isProfileAvailable(): Observable<boolean> {
    return this.fetchProfile().pipe(map(res => (typeof res === 'object' ? !!res?.userSettings : false)));
  }

  // Keep it, can be useful
  // get currentProfileMailAddress(): string {
  //   return this.currentProfile?.userSettings?.contactData?.mailAddress?.[0] || '';
  // }
}
